import React from "react";
import { capitalize } from "../../../../resources/Functions";
import "./TileGrid.css";

const TileGrid = (props) => {
  const { grid, tileColors } = props;

  return (
    <>
      <div className="tg-grid-container">
        <div className="tg-grid">
          {Object.values(grid.letters).map((row, i) => {
            return Object.values(row).map((tile, j) => {
              let borderColor = '';
              if (grid.letters[i][j] === "" || grid.colors[i][j] > 0) {
                borderColor = 'rgb(45, 45, 45)'
              } else {
                borderColor = 'rgb(117, 117, 117)'
              }
              return (
                <div
                  className="tg-tile"
                  key={j}
                  data-testid={"tile-" + i + "-" + j}
                  style={{
                    backgroundColor:
                      grid.letters[i][j] === ""
                        ? "#00000000"
                        : tileColors[grid.colors[i][j]],
                    borderColor: borderColor
                  }}
                >
                  <div 
                    className="tg-tile-letter"
                    style={{
                      color: 'white',
                      opacity: tile === '0' ? 0 : 1
                    }}
                  >{capitalize(tile)}</div>
                </div>
              );
            });
          })}
        </div>
      </div>
    </>
  );
};

export default TileGrid;
