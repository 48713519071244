import firebase from 'firebase';
import "firebase/analytics";

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyDmIvbx9thTuZTE4-5M1opsAbRfo_h8MzA",
  authDomain: "wwu-wordle.firebaseapp.com",
  databaseURL: "https://wwu-wordle-default-rtdb.firebaseio.com",
  projectId: "wwu-wordle",
  storageBucket: "wwu-wordle.appspot.com",
  messagingSenderId: "965180060653",
  appId: "1:965180060653:web:c8e48243b68b0d9cbaade4",
  measurementId: "G-Z7HD0MQK2Q"
};
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
export var rtDatabase = firebase.database();
export var fireFuncs = firebase.functions();
export var fireAnalytics = firebase.analytics();
if (window.location.hostname === "localhost" && useEmulator) {
  rtDatabase.useEmulator("localhost", 8001);
  fireFuncs.useEmulator("localhost", 5001);
}
