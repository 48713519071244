import React from "react";
import { Spinner } from "react-bootstrap";
import { tileColors } from "../Wordle";
import "./Keyboard.css";

const Keyboard = (props) => {
  const { 
    keyboard, 
    keyColors,
    handleNewLetter, 
    handleSubmit, 
    handleBackSpace,
    submitting
  } = props;

  return (
    <>
      <div className="w-kb">
        {keyboard.map((row, i) => (
          <div key={i} className="w-kb-row">
            {row.map((letter, j) => {
              let bgColor = ''
              if (letter !== '') {
                bgColor = keyColors[letter] !== undefined ? tileColors[keyColors[letter]] : 'rgb(129, 129, 129)';
              }
              let keyContent = typeof letter === "string" ? letter.toUpperCase() : letter;
              if (keyContent === 'ENTER') {
                keyContent = submitting ? <Spinner animation="border" size="sm" /> : 'ENTER';
              }
              return <button
                key={j}
                data-testid={
                  /^\w+$/.test(letter) ? "key-" + letter : "key-backspace"
                }
                id={/^\w+$/.test(letter) ? "key-" + letter : "key-backspace"}
                type={letter === "enter" ? "submit" : "button"}
                onClick={
                  /^\w$/.test(letter)
                    ? () => handleNewLetter(letter)
                    : letter === "enter"
                      ? handleSubmit
                      : () => handleBackSpace()
                }
                className={
                  letter === ""
                    ? "w-kb-spacer"
                    : "w-kb-letter" +
                      (/^\w$/.test(letter) ? "" : " w-kb-one-point-five")
                }
                style={{
                  backgroundColor: bgColor
                }}
              >
                {keyContent}
              </button>
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default Keyboard;
