import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Wordle from './components/pages/wordle/Wordle';
import Error from "./components/pages/error/Error";
import Test from './components/pages/test/Test';
import { CookiesProvider } from 'react-cookie';
import './App.css';

const App = () => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Switch>
            <Route path="/" component={() => <Wordle />} exact/>
            <Route path="/test" component={Test} exact/>
            <Route render={(props) => <Error {...props}/>}/>
        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
