import React, { useEffect, useState } from 'react';
import { sleep } from '../../../../resources/Functions';
import './Alert.css';

const Alert = props => {
  const { trigger, message, onHide = () => {} } = props;
  const [show, setShow] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) {
      setShow(true);
      sleep(1200).then(() => setShow(false));
      sleep(1600).then(() => onHide());
    } else {
      setMounted(true);
    }
  }, [trigger])
  
  return <>
    <div 
      className={"a-container " + (show ? '' : "a-container-invisible")}
    >
      <h1 className="a-message">{message}</h1>
    </div>
  </>
}

export default Alert;