import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../../../resources/Functions";
import { tileColors } from "../Wordle";
import './WelcomeModal.css';

const WelcomeModal = (props) => {
  const { show, onHide } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  const sampleWords = [
    'mcvay',
    'halve',
    'trees'
  ]

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      style={{color: 'black'}}
    >
      <Modal.Header closeButton>
        <div className="wm-header">
          <p className="wm-text">
            Guess the <span className="wm-bold">WWUrdle</span> in 6 tries.
          </p>
          <p className="wm-text">
            Each guess must be a valid five-letter word. 
            Hit the enter button to submit.
          </p>
          <p className="wm-text wm-last-header-text">
            After each guess, the color of the tiles will change 
            to show how close your guess was to the word.
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h2 className="wm-examples-title">Examples</h2>
        <div className="wm-example-word">
          {[...sampleWords[0]].map((letter, i) => (
            <div 
              key={i}
              className={"wm-tile"}
              style={{
                backgroundColor: i === 0 ? tileColors[3] : '',
                borderColor: i === 0 ? tileColors[3] : '',
              }}
            >
              <div 
                className="wm-tile-letter"
                style={{
                  color: i === 0 ? 'white' : '',
                }}
              >{letter.toUpperCase()}</div>
            </div>
          ))}
        </div>
        <p className="wm-text">
          The letter <span className="wm-bold">M</span> is in the word 
          and in the correct spot.
        </p>
        <div className="wm-example-word">
          {[...sampleWords[1]].map((letter, i) => (
            <div 
              key={i}
              className={"wm-tile"}
              style={{
                backgroundColor: i === 1 ? tileColors[2] : '',
                borderColor: i === 1 ? tileColors[2] : ''
              }}
            >
              <div 
                className="wm-tile-letter"
                style={{
                  color: i === 1 ? 'white' : '',
                }}
              >{letter.toUpperCase()}</div>
            </div>
          ))}
        </div>
        <p className="wm-text">
          The letter <span className="wm-bold">A</span> is in the word 
          but in the wrong spot.
        </p>
        <div className="wm-example-word">
          {[...sampleWords[2]].map((letter, i) => (
            <div 
              key={i}
              className={"wm-tile"}
              style={{
                backgroundColor: i === 4 ? tileColors[1] : '',
                borderColor: i === 4 ? tileColors[1] : '',
              }}
            >
              <div 
                className="wm-tile-letter"
                style={{
                  color: i === 4 ? 'white' : '',
                }}
              >{letter.toUpperCase()}</div>
            </div>
          ))}
        </div>
        <p className="wm-text">
          The letter <span className="wm-bold">S</span> is not in the 
          word in any spot.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="wm-footer">
          <p className="wm-text wm-bold">
            A new WWUrdle will be available each day!
          </p>
        </div>
      </Modal.Footer>
    </Modal>
  </>
};

export default WelcomeModal;