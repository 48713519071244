import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { sleep, checkResult, copyStringToClipboard } from "../../../../resources/Functions";
import './DoneModal.css';

const DoneModal = (props) => {
  const { show, onHide, finalColors, setAlert, title, won } = props;
  const [intShow, setIntShow] = useState(show);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  const handleShare = () => {
    const squares = {
      1: '⬛️',
      2: '🟧',
      3: '🟩'
    }
    const emoji = won ? '💪' : '😭';
    let result = 'WWUrdle ' + new Date().toLocaleDateString() + ' ' + emoji + '\n\n';
    Object.values(finalColors).every((line, i) => {
      Object.values(line).forEach(key => {
        result += squares[key];
      })
      if (checkResult(line) || i === 5) {
        result += '\nwwurdle.com';
        return false;
      } else {
        result += '\n';
        return true;
      }
    })
    copyStringToClipboard(result);
    // setAlert({
    //   trigger: Date.now(),
    //   message: 'Copied results to clipboard'
    // })
    setShowMessage(true);
    sleep(1000).then(() => setShowMessage(false));
  }

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      style={{color: 'black'}}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dm-btn-container">
          <Button
            variant="success"
            className="dm-btn"
            onClick={handleShare}
          >
            <div className="dm-btn-text">SHARE <i className="bi-share" /></div>
          </Button>
          <div 
            className={"a-container " + (showMessage ? '' : "a-container-invisible")}
          >
            <h1 className="a-message">Copied results to clipboard</h1>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
};

export default DoneModal;