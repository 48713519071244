import React from 'react';
import { useCookies } from 'react-cookie';
import { Button } from 'react-bootstrap';

const Test = () => {
  const [cookies, setCookie] = useCookies(['counter']);

  const increment = () => {
    if (cookies.counter) {
      setCookie('counter', parseInt(cookies.counter) + 1, { path: '/test' });
    } else {
      setCookie('counter', 1, { path: '/test' });
    }
  }

  return <>
    <Button
      onClick={increment}
    >Increment cookie</Button>
    {cookies.counter && <h1>Counter: {cookies.counter}</h1>}
  </>
}

export default Test;