import React, { useEffect, useState } from "react";
import { fireFuncs, rtDatabase } from './../../../Fire';
import { Form, Button } from "react-bootstrap";
import TileGrid from "./components/TileGrid";
import Keyboard from "./components/Keyboard";
import Alert from "./components/Alert";
import DoneModal from "./components/DoneModal";
import WelcomeModal from './components/WelcomeModal';
import { checkResult, roundToNextTime, sleep } from "../../../resources/Functions";
import { useCookies } from 'react-cookie';
import BackspaceIcon from '@mui/icons-material/Backspace';
import "./Wordle.css";

export const tileColors = {
  0: "rgba(58, 58, 60, 0)",
  1: "rgb(58, 58, 60)",
  2: "rgb(203, 109, 17)",
  3: "rgb(62, 92, 37)",
};

const blankGrid = {
  letters: {
    0: { 0: "", 1: "", 2: "", 3: "", 4: "" },
    1: { 0: "", 1: "", 2: "", 3: "", 4: "" },
    2: { 0: "", 1: "", 2: "", 3: "", 4: "" },
    3: { 0: "", 1: "", 2: "", 3: "", 4: "" },
    4: { 0: "", 1: "", 2: "", 3: "", 4: "" },
    5: { 0: "", 1: "", 2: "", 3: "", 4: "" },
  },
  colors: {
    0: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    1: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    2: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    3: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    4: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
    5: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
  },
  index: {
    i: 0,
    j: 0,
  },
  guessIndex: 0
};

const keyboard = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["", "a", "s", "d", "f", "g", "h", "j", "k", "l", ""],
  [
    "enter",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
    <BackspaceIcon />,
  ],
];

const useCs = true;
const Wordle = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['grid', 'keyColors', 'finished', 'won', 'returning']);
  let stateGrid = blankGrid;
  if (cookies.grid) {
    stateGrid = cookies.grid;
  }
  let stateFinished = false;
  if (cookies.finished) {
    stateFinished = parseInt(cookies.finished);
  }
  let stateWon = false;
  if (cookies.won) {
    stateWon = parseInt(cookies.won);
  }
  let stateKeyColors = {};
  if (cookies.keyColors) {
    stateKeyColors = cookies.keyColors;
  }
  let stateReturning = false;
  if (cookies.returning) {
    stateReturning = true;
  }
  const [grid, setGrid] = useState(useCs ? stateGrid : blankGrid);
  const [keyColors, setKeyColors] = useState(stateKeyColors);
  const [resultMap, setResultMap] = useState(null)
  const [alert, setAlert] = useState({
    trigger: Date.now(),
    message: ''
  })
  const [showModal, setShowModal] = useState({
    done: {
      show: false,
      title: ''
    }, 
    welcome: {
      show: !stateReturning
    }
  });
  const [finished, setFinished] = useState(useCs ? stateFinished : false);
  const [won, setWon] = useState(useCs ? stateWon : false);
  const [submitting, setSubmitting] = useState(false);
  const [started, setStarted] = useState(false);

  // attach listener that resets cookies
  useEffect(() => {
    let loaded = false;
    const ref = rtDatabase.ref('resetCookies');
    ref.on('value', () => {
      if (loaded) {
        setAlert({
          trigger: Date.now(),
          message: "Word has been changed",
          onHide: () => {
            resetCookies('half');
            sleep(500).then(() => window.location.reload());
          }
        })
      }
      loaded = true;
    }, e => {
      console.error("Error getting cookie reset listener")
      console.error(e);
    })

    return () => ref.off();
  }, [])

  // attach key listeners
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // open modal if finished on load
  useEffect(() => {
    if (finished) {
      setShowModal(won? winModal : loseModal);
    }
  }, [])

  // reset cookies 
  const resetCookies = (type = 'full') => {
    removeCookie('grid', { path: '/' });
    removeCookie('keyColors', { path: '/' });
    removeCookie('finished', { path: '/' })
    removeCookie('won', { path: '/' })
    if (type === 'full') {
      removeCookie('returning', { path: '/' })
    }
  }

  const updateCookies = () => {
    const expirDate = new Date(roundToNextTime(new Date(), 0, 0));
    setCookie('grid', grid, { path: '/', expires: expirDate });
    setCookie('keyColors', keyColors, { path: '/', expires: expirDate });
    setCookie('finished', finished ? 1 : 0, { path: '/', expires: expirDate })
    setCookie('won', won ? 1 : 0, { path: '/', expires: expirDate })
    setCookie('returning', 1, { path: '/', expires: new Date('June 13, 2022') })
  }

  useEffect(() => {
    if (started) {
      updateCookies();
    }
  }, [grid, finished, won, started])

  const handleNewLetter = (letter) => {
    setStarted(true);
    setGrid((prev) => {
      if (
        (prev.index.i === prev.guessIndex + 1 && prev.index.j === 0) || 
        prev.index.i > 5 || 
        finished ||
        submitting
      ) {
        return prev;
      }
      return {
        ...prev,
        letters: {
          ...prev.letters,
          [prev.index.i]: {
            ...prev.letters[prev.index.i],
            [prev.index.j]: letter,
          },
        },
        index:
          prev.index.j === 4
            ? {
                i: prev.index.i + 1,
                j: 0,
              }
            : {
                ...prev.index,
                j: prev.index.j + 1,
              },
      };
    });
  };

  const handleBackSpace = () => {
    setStarted(true);
    setGrid((prev) => {
      let i0 = prev.index.j === 0 ? prev.index.i - 1 : prev.index.i;
      let j0 = prev.index.j === 0 ? 4 : prev.index.j - 1;
      if (
        (prev.index.i === prev.guessIndex && prev.index.j === 0) ||
        submitting
      ) {
        return prev;
      }
      return {
        ...prev,
        letters: {
          ...prev.letters,
          [i0]: {
            ...prev.letters[i0],
            [j0]: "",
          },
        },
        index:
          prev.index.j === 0
            ? {
                i: prev.index.i - 1,
                j: 4,
              }
            : {
                ...prev.index,
                j: prev.index.j - 1,
              },
      };
    });
  };

  const handleKeyDown = (e) => {
    // key pressed is a letter
    if (65 <= e.which && e.which <= 90 && e.key !== "Dead" && !e.metaKey) {
      document.getElementById("key-" + e.key).click();
    }
    // backspace
    if (e.which === 8) {
      document.getElementById("key-backspace").click();
    }
    // enter
    if (e.which === 13) {
      document.getElementById("key-enter").click();
    }
  };

  const winModal = prev => ({
    ...prev, 
    done: {
      ...prev.done, 
      show: true,
      title: 'Mmm. Strong work. 😤'
    }
  })

  const loseModal = prev => ({
    ...prev,
    done: {
      ...prev.done,
      show: true,
      title: 'Ooo, rough. Better luck next time. 👊'
    }
  })

  // send guessed word to server, save resulting color map
  const handleSubmit = (e) => {
    e.preventDefault();
    let word = '';
    if (!finished) {
      word = Object.values(grid.letters[grid.guessIndex]).join('');
    }
    if (finished) {
      setShowModal(won? winModal : loseModal);
    } else if (word.length === 5) {
      setSubmitting(true);
      fireFuncs.httpsCallable("checkWord")({
        word: word,
        dateStr: new Date().toLocaleDateString()
      }).then((result) => {
        const colorMap = result.data.colorMap;
        setResultMap(colorMap);
      }).catch(e => {
        console.error("Error with function");
        console.error(e);
      })
    }
  };

  const updateKeyColors = (result, letters) => {
    let updates = {};
    Object.keys(result).forEach((key) => {
      if (
        (result[key] > updates[letters[key]] || updates[letters[key]] === undefined) && 
        (result[key] > keyColors[letters[key]] || keyColors[letters[key]] === undefined)
      ) {
        updates[letters[key]] = result[key];
      }
    })
    setKeyColors(prev => ({
      ...prev,
      ...updates
    }))
  }

  // if resulting color map changes, update grid
  useEffect(() => {
    if (resultMap) {
      // cloud func returns current time in first array spot if guess not in word list
      if (resultMap[0] > 4) {
        setAlert({
          trigger: Date.now(),
          message: "Not in word list"
        })
        setSubmitting(false);
      } else {
        if (checkResult(resultMap)) {
          setFinished(true);
          setWon(true);
          setAlert({
            trigger: Date.now(),
            message: "Genius",
            onHide: () => setShowModal(winModal),
          })
          setSubmitting(false);
        } else if (grid.guessIndex === 5) {
          setFinished(true);
          setAlert({
            trigger: Date.now(),
            message: "Farse",
            onHide: () => setShowModal(loseModal)
          })
        }
        updateKeyColors(resultMap, grid.letters[grid.guessIndex]);
        setGrid(prev => {
          return {
            ...prev,
            colors: {
              ...prev.colors,
              [prev.guessIndex]: resultMap
            },
            guessIndex: prev.guessIndex + 1
          }
        })
      }
    }
  }, [resultMap])

  useEffect(() => {
    setSubmitting(false);
  }, [grid.colors])

  return (
    <>
      <img className="w-watermark" src="./images/assetWhite.png" />
      <div className="w-page">
        <div className="w-title-container">
          <button 
            type="button" 
            className="w-title-wrapper"
            onClick={() => setShowModal(prev => ({...prev, welcome: {show: true}}))}
          >
            <img className="w-title-img" src="./images/assetWhite.png" />
            <h1 className="w-title">WWUrdle</h1>
          </button>
        </div>
        <div className="w-tile-big-container">
          <Alert 
            {...alert}
          />
          <div className="w-tile-container" >
            <img src="./images/aspectRatio.png" style={{height: '100%', width: 'auto', position: 'relative'}} />
            <TileGrid
              grid={grid}
              tileColors={tileColors}
            />
          </div>
        </div>
        <div className="w-keyboard-container">
          <Form className="w-grid-form w-half-content" onSubmit={handleSubmit}>
            <Keyboard
              keyboard={keyboard}
              keyColors={keyColors}
              handleNewLetter={handleNewLetter}
              handleSubmit={handleSubmit}
              handleBackSpace={handleBackSpace}
              submitting={submitting}
            />
            {/* <Button
              className="w-reset-btn"
              variant="dark"
              type="button"
              onClick={() => {
                resetCookies();
                sleep(500).then(() => window.location.reload());
              }}
            >Reset</Button> */}
          </Form>
        </div>
      </div>
      {showModal.done.show && <DoneModal 
        show={showModal.done.show}
        onHide={() => setShowModal(prev => ({
          ...prev, 
          done: {...prev.done, show: false, title: ''}
        }))}
        finalColors={grid.colors}
        setAlert={setAlert}
        title={showModal.done.title}
        won={won}
      />}
      {showModal.welcome.show && <WelcomeModal 
        show={showModal.welcome.show}
        onHide={() => setShowModal(prev => ({
          ...prev, 
          welcome: {...prev.welcome, show: false}
        }))}
      />}
    </>
  );
};

export default Wordle;
