export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

/*
    function: toMili 
    ================================================
    str: should be time of format "hh:mm"
    return: int of the time converted to miliseconds
*/
export const toMili = (str) => {
  var hours = parseInt(str.replace(/(\d+):(\d+)/g, "$1"));
  var minutes = parseInt(str.replace(/(\d+):(\d+)/g, "$2"));
  return (hours * 60 + minutes) * 60 * 1000;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const checkResult = (result) => {
  let perfect = true;
  Object.values(result).every((color) => {
    if (color !== 3) {
      perfect = false;
      return false;
    }
    return true;
  });
  return perfect;
};

export const roundToMidnight = date => {
  const rounded = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  return rounded.getTime();
} 

const dayInMs = 24 * 60 * 60 * 1000;
export const roundToNextMidnight = date => {
  const rounded = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  return rounded.getTime() + dayInMs;
}

export const roundToNextTime = (date, hour, minute) => {
  const rounded = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, 0);
  let t = rounded.getTime();
  if (Date.now() > t) t += dayInMs;
  return t;
} 